// Variables
$website-width: 1800px;
$main-color: #75CBE5;
$light-color: #f4f4f4;
$medium-color: #ccc;
$dark-color: #333;
$bg-image: url('../img/showcase.jpg');
$footer-height: 5rem;

//General Styles
*{
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Dosis', sans-serif;
    line-height: 1.6;
    background: #fff;
    position: relative;
    min-height: 100vh;
}

a{
    text-decoration: none;
    color: $dark-color;
}

ul{
    list-style: none;
}

h2, h3, h4{
    text-transform: uppercase;
}

img{
    width: 100%;
}

// Set Text Color:
@function set-text-color($color) {
    @if(lightness($color) > 50) {
        @return #000;
    } @else {
        @return #fff;
    }
}