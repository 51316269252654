@import 'config';
@import 'utilities';
@import 'item_grid';

// Content wrapper to keep footer at bottom
#content-wrap{
    padding-bottom: $footer-height;
}

// Logo
#logo{
    // width: 70px;
    // height: 70px;
    font-size: 2.5rem;
    color: #fff;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
}

//Main Nav
#main-nav{
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;

    ul{
        display: flex;
       
        li{
            padding: 1rem 1.5rem;
           
            a{
                color: #fff;
                text-transform: uppercase;
                border-bottom: 3px transparent solid;
                padding-bottom: 0.5rem;
                transition: border-color 0.5s;
                
                &:hover{
                    border-color: $main-color;
                }

                &.current{
                    border-color: $main-color;
                }
            }
        }
    }
}

// Header
#header{
    //Header Home
    &-home{
        background: $bg-image no-repeat center right / cover;
        height: 100vh;
        color: #fff;
        
        //Header Content
        .header-content{
            text-align: center;
            padding-top: 20%;

            h1{
                font-size: 4rem;
                line-height: 1.2;
                text-align: center;
            }
        }
    }

    //Header Inner
    &-inner{
        background: $bg-image no-repeat 20% 30% / cover;
        height: 5.5rem;
        border-bottom: 3px solid $main-color;
    }
}

// Home Section
#home{
    //Home Section A
    &-a{
        .specials{
            margin-top: 3rem;
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(4, 1fr);
        }

        a{
            margin: 0 auto;
            margin-top: 3rem;
        }
    }
}

// About Sections
#about{
    //About A
    &-a{
        .about-info{
            display: grid;
            grid-template-areas:
                'bio-image bio bio';
            grid-gap: 1.2rem;
        }

        //Grid Areas
        .bio-image{
            grid-area: bio-image;
        }

        .bio{
            grid-area: bio;
            border-left: 3px solid $main-color;
            padding: 0.8rem;
        }

        .award-1{
            grid-area: aw1;
        }
        
        .award-2{
            grid-area: aw2;
        }
        
        .award-3{
            grid-area: aw3;
        }

        .award-1 .fas,
        .award-2 .fas,
        .award-3 .fas{
            color: $main-color;
            margin: 1rem;
        }
    }

    //About B
    &-b{
        .skills{
            margin-top: 3rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            .skill{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 2rem;
                text-align: center;
            }
        }
    }

    //About C
    &-c{
        .about-logos{
            display:grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 2rem;

            img{
                width: 70%;
            }
        }
    }

    //About D
    &-d{
        .testimonials{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 0.5rem;

            ul{
                list-style: none;
                margin-top: 1rem;
                display: flex;
                align-items: center;
            }

            .quote-wrap{
                border: 1px solid $medium-color;
                border-radius: 5px;
                padding: 0.5rem;
                overflow: hidden;

                p{
                    padding-bottom: 100%;
                    margin-bottom: -100%;
                }
            }

            img{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 0.5rem;

            }
        }
    }
}

// Contact Page
#contact {
    &-body{
        background-color: $main-color;
    }

    &-a{
        background-color: #fff;

        .text-fields{
            display: grid;
            grid-template-areas:
                'name email'
                'subject phone'
                'message message'
                // 'recatpcha recaptcha'
                ;
            grid-gap: 1.2rem;
            margin-bottom: 1.2rem;

            //Grid Areas
            .name-input{
                grid-area: name;
            }
            .subject-input{
                grid-area: subject;
            }
            .email-input{
                grid-area: email;
            }
            .phone-input{
                grid-area: phone;
            }
            .message-input{
                grid-area: message;
                height: 100px;
            }
            .text-input{
                padding: 0.5rem 1rem;
            }
            // .recaptcha {
            //     grid-area: recaptcha;
            //     display: block;
            // }
        }
        
        button[type='submit']{
            width: 50%;
        }
    }

    &-b{
        .contact-info{
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            text-align: center;
        }

        a{
            color: white;
        }
    }

    &-c{
        h1{
            text-align: center;
            font-size: 3rem;
            color: white;
        }
    }
}

//Footer
#main-footer{
    background: $dark-color;
    color: set-text-color($dark-color);
    height: $footer-height;
    position: absolute;
    bottom: 0;
    width: 100%;

    .footer-content{
        display: flex;
        height: 5rem;
        align-items: center;

        .social{
            margin: 0 auto;
        }

        .social .fab{
            margin-right: 1rem;
            color: white;
            border: 2px #fff solid;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            line-height: 20px;
            text-align: center;
            padding: 0.5rem;
            transition: all 0.5s;

            &:hover{
                background: $main-color;
            }
        }
    }
}

// Import Media Queries
@import "media";